import Rails from '@rails/ujs';
Rails.start();

import './controllers'
import MicroModal from "micromodal";

const initModals = (event) => {
  MicroModal.init({
    disableFocus: true,
    onShow: (modal, el, event) => {
      event.preventDefault()
    }
  });
}

document.addEventListener('DOMContentLoaded', initModals)
document.addEventListener('ajax:success', initModals)
document.addEventListener('initModals', initModals)
