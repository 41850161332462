import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['addons', 'form', 'loading', 'variant', 'quantity'];

  // Triggered when the user selects or deselects a product
  async selectedProductsChanged(_event) {
    // Show a loading indicator
    this.addonsTarget.innerHTML = this.loadingTarget.innerHTML;

    // Retrieve a list of available addons for the currently selected variants.
    const response = await fetch(
      this.addonUrl,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          variant_ids: this.selectedVariantIds,
          quantity: this.quantity
        })
      }
    );

    // Show a list of available addons
    const html = await response.text();
    this.addonsTarget.innerHTML = html;
  }

  submit(_event) {
    this.formTarget.setAttribute('data-remote', false);
  }

  get addonUrl() {
    return this.element.dataset.addonUrl;
  }

  get selectedVariantIds() {
    return this.variantTargets.filter(el => el.checked).map(el => el.dataset.variantId);
  }

  get quantity() {
    return this.quantityTarget.value;
  }
}
