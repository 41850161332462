import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    mapkit.init({
      authorizationCallback: (done) => {
        done(this.data.get('token'));
        this.map()
      }
    });
  }

  map() {
    const latitude = parseFloat(this.data.get('latitude'));
    const longitude = parseFloat(this.data.get('longitude'));

    const center = new mapkit.Coordinate(latitude, longitude);
    const span = new mapkit.CoordinateSpan(0.0125, 0.0125);
    const region = new mapkit.CoordinateRegion(center, span);

    let map = new mapkit.Map(this.data.get('target'), {
      region: region,
      showsCompass: mapkit.FeatureVisibility.Hidden,
      showsZoomControl: true,
      showsMapTypeControl: true
    });

    const annotation = new mapkit.MarkerAnnotation(center, {
      title: this.data.get('annotation-title'),
      subtitle: this.data.get('annotation-subtitle'),
      displayPriority: 1000
    });

    map.addAnnotation(annotation);

  }

}
