import { Controller } from "@hotwired/stimulus";
import { useIntersection } from 'stimulus-use';

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    useIntersection(this);
  }

  appear() {
    fetch(this.urlValue, {
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': this.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
      .then(this.checkHttpResponse)
      .then(response => response.text())
      .then(html => {
        this.element.innerHTML = html;
      });
  }

  /**
   * The `checkHttpResponse` raises an exception whenever there is an HTTP status
   * code that is an error i.e. on any non-2xx status.
   *
   * @param {Response} response The HTTP Response object
   * @return {Response} The original HTTP response object
   */
  checkHttpResponse(response) {
    if (response.status >= 200 && response.status < 300) return response;

    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }

  get csrfToken() {
    const element = document.head.querySelector(`meta[name="csrf-token"]`);
    return element && element.getAttribute('content');
  }
}
