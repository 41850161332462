import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['sticky', 'parentBox' ]

  connect() {
    this.didScroll();
    this.applyStyles();
    this.detectMutations();
  }

  get topPadding() {
    if (!this.data.has('top')) {
      return 10;
    }

    return parseInt(this.data.get('top'));
  }

  applyStyles() {
    if (!this.data.has('minWidth') ||
        window.innerWidth >= +this.data.get('minWidth')) {
      const parentPadding = window.getComputedStyle(
        this.parentBoxTarget, null
      ).getPropertyValue('padding-left');
      this.stickyTarget.style.transition = 'top 500ms ease 0s';
      this.stickyTarget.style.position = 'absolute';
      this.stickyTarget.style.left = parentPadding;
      this.stickyTarget.style.right = parentPadding;
    } else {
      this.stickyTarget.style.position = 'revert';
    }
  }

  didScroll() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.moveStickyTarget.bind(this), 200);
  }

  moveStickyTarget() {
    if (this.hasStickyTarget) {
      const stickyRect = this.stickyTarget.getBoundingClientRect();
      const parentBoxRect = this.parentBoxTarget.getBoundingClientRect();

      if (parentBoxRect.top < 0 && stickyRect.height < window.innerHeight) {
        var newTopPos = (-(parentBoxRect.top)) + this.topPadding;
        console.log('move target', this.topPadding, newTopPos);
        if (newTopPos + stickyRect.height > parentBoxRect.height) {
          newTopPos = parentBoxRect.height - stickyRect.height;
        }
        this.stickyTarget.style.top = `${newTopPos}px`;
      } else {
        this.stickyTarget.style.top = "0px";
      }
    }
  }

  detectMutations() {
    this.observer = new MutationObserver((mutationList, observer) => {
      this.applyStyles();
      this.moveStickyTarget()
    });

    this.observer.observe(
      this.parentBoxTarget,
      { attributes: false, childList: true }
    );
  }

}
