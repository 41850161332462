import { Controller } from '@hotwired/stimulus';
import ahoy from 'ahoy.js';

export default class extends Controller {
  static targets = ['form', 'output', 'cardToken', 'expDate', 
    'phoneNumber', 'acceptTerms', 'submit'];

  connect() {
    window.addEventListener('message', this.handleResponse.bind(this), false);
    this.resizeIframe();
  }

  disconnect() {
    window.removeEventListener('message', this.handleResponse.bind(this), false);
    this.errors = null;
  }

  handleResponse(event) {
    if ((typeof(event.data) !== "string") || event.data === "") { return };

    const result = JSON.parse(event.data);

    if (result.validationError) {
      this.outputTarget.classList.remove('d-none')
      this.outputTarget.innerText = result.validationError;
      ahoy.track('Credit Card Validation Error', { error: result.validationError });
    } else {
      this.cardTokenTarget.value = result.message;
      this.expDateTarget.value = result.expiry;
      this.outputTarget.classList.add('d-none')
      ahoy.track('Credit Card Validated');
    }
  }

  validate(event) {
    if (this.cardTokenTarget.value && this.phoneNumberTarget.value
        && (!this.hasAcceptTermsTarget || this.acceptTermsTarget.checked) ) {
      this.submitTarget.disabled = false;
    } else {
      this.submitTarget.disabled = true;
    }
  }

  resizeIframe() {
    const iframe = document.getElementById('tokenFrame');
    iframe.style.width  = `${iframe.contentWindow.document.body.scrollWidth}px`;
    iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight + 100}px`;
  }
}
