import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['form', 'slotList', 'date'];

  getAvailableReservations(event) {
    this.slotListTarget.classList.add('slot-list--loading')
    const slots = document.querySelectorAll('.slot-list__slot');
    slots.forEach(e => e.parentNode.removeChild(e))

    Rails.ajax({
      url: `/locations/springdale-oh/reservation?date=${this.dateTarget.value}`,
      type: 'GET',
      dataType: 'script',
      complete: (xhr) => { this.stopLoading() }
    })
  }

  stopLoading() {
    this.slotListTarget.classList.remove('slot-list--loading')
  }
}
